import React from "react";
import {
  Box,
  Button,
  Grid,
  Link,
  ListItemText,
  MenuList,
  Paper,
  Typography,
} from "@mui/material";
import classes from "./App.module.css";
import profilePic from "./assets/ProfilePic.webp";
import computerImg from "./assets/computer.jpg";
import physicsImg from "./assets/physics.jpg";
import biologyImg from "./assets/biology.jpg";
import chemistryImg from "./assets/chemistry.jpg";
import mathsImg from "./assets/maths.jpg";
import banner from "./assets/Photo.webp";
import "animate.css";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import PhoneIcon from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

function App() {
  const smoothScrollTo = (targetId) => {
    const target = document.getElementById(targetId);
    if (target) {
      window.scrollTo({
        top: target.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "30px",
          backgroundColor: "gray",
          display: "flex",
          justifyContent: "space-between",
          color: "white",
        }}
      >
        <div
          style={{ marginLeft: "20px", display: "flex", alignItems: "center" }}
        >
          Monday to Friday at 9am to 7pm
        </div>
        <div style={{ display: "flex", gap: "20px", marginRight: "20px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <PhoneIcon fontSize="small" /> 0340-8398099 & 0330-0389955
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <MailOutlineIcon fontSize="small" /> info@eclassnetwork.com
          </div>
        </div>
      </div>
      <Box className={classes.heroSection} id="home">
        <Box className={classes.overlay}></Box>
        <Box className={classes.navbarContainer}>
          <Box className={classes.navItem}>
            <a href="#" onClick={() => smoothScrollTo("home")}>
              Home
            </a>
            <a href="#" onClick={handleClick}>
              Classes
            </a>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              sx={{ marginTop: "10px", marginLeft: "-20px" }}
            >
              <Paper sx={{ width: 320 }}>
                <MenuList dense>
                  <MenuItem>
                    <ListItemText inset>Schedule/Timings</ListItemText>
                  </MenuItem>
                  <MenuItem>
                    <ListItemText inset>Courses</ListItemText>
                  </MenuItem>
                  <MenuItem>
                    <ListItemText inset>Results</ListItemText>
                  </MenuItem>
                </MenuList>
              </Paper>
            </Menu>
            <a href="#" onClick={() => smoothScrollTo("aboutus")}>
              About us
            </a>
            <a href="#" onClick={() => smoothScrollTo("contact")}>
              Contact us
            </a>
          </Box>
        </Box>
        <Box className={classes.heroSectionHeading}>
          <ScrollAnimation animateIn="bounceInLeft">
            <Typography variant="h1">E-Classes</Typography>
          </ScrollAnimation>
        </Box>
      </Box>

      <Box className={classes.bookSectionWrapper} id="classes">
        <ScrollAnimation animateIn="backInDown">
          <div style={{ textAlign: "center" }}>
            <Typography variant="h4">Join eclassnetwork, We Offer</Typography>
            <Typography variant="p">
              Join our E-classes offering subjects from grade 9th to 12th,
              including O-levels and A-levels curriculum. <br></br> Experience
              comprehensive learning tailored to your academic needs.
            </Typography>
          </div>
        </ScrollAnimation>
        <Box className={classes.bookColumnContainer}>
          <ScrollAnimation animateIn="backInLeft">
            <Box className={classes.bookSection}>
              <img
                className={classes.imageWidth}
                src={mathsImg}
                alt="City of Stars"
              />
              <Box className={classes.booksDetail}>
                <Box>
                  <Typography variant="h6">Mathmatics E-Classes:</Typography>
                  <Typography variant="body1">
                    Unlock the world of numbers with our engaging and
                    interactive lessons. Master concepts at your own pace and
                    see how math can empower you to solve problems confidently.
                    Join us and multiply your potential!
                  </Typography>
                </Box>
              </Box>
            </Box>
          </ScrollAnimation>
          <ScrollAnimation animateIn="backInRight">
            <Box className={classes.bookSection}>
              <img
                className={classes.imageWidth}
                src={physicsImg}
                alt="The Pigeon Chronicles"
              />
              <Box className={classes.booksDetail}>
                <Box>
                  <Typography variant="h6">Physics E-Classes:</Typography>
                  <Typography variant="body1">
                    Explore the wonders of Physics with our engaging and
                    interactive learning modules. Perfect for students and
                    enthusiasts, our lessons make complex concepts easy and fun
                    to understand. Start your journey into the world of Physics
                    today!
                  </Typography>
                </Box>
              </Box>
            </Box>
          </ScrollAnimation>
        </Box>
        <Box className={classes.bookColumnContainer}>
          <ScrollAnimation animateIn="backInLeft">
            <Box className={classes.bookSection}>
              <img
                className={classes.imageWidth}
                src={chemistryImg}
                alt="The Elemental"
              />
              <Box className={classes.booksDetail}>
                <Box>
                  <Typography variant="h6">Chemistry E-Classes</Typography>
                  <Typography variant="body1">
                    Unlock the wonders of Chemistry with our engaging,
                    interactive lessons. Master key concepts, conduct virtual
                    experiments, and boost your grades with ease. Start your
                    scientific journey today!
                  </Typography>
                </Box>
              </Box>
            </Box>
          </ScrollAnimation>
          <ScrollAnimation animateIn="backInRight">
            <Box className={classes.bookSection}>
              <img
                src={biologyImg}
                alt="What is Home – a bedtime story"
                className={classes.imageWidth}
              />
              <Box className={classes.booksDetail}>
                <Box>
                  <Typography variant="h6">Biology E-Classes</Typography>
                  <Typography variant="body1">
                    Explore the fascinating world of Biology with our
                    comprehensive learning modules. Dive into engaging lessons,
                    interactive content, and real-life applications to master
                    the science of life. Start your journey today and discover
                    the wonders of the biological world!
                  </Typography>
                </Box>
              </Box>
            </Box>
          </ScrollAnimation>
        </Box>
        <Box className={classes.bookColumnContainer}>
          <ScrollAnimation animateIn="backInUp">
            <Box className={classes.bookSection}>
              <img
                src={computerImg}
                alt="As you Grow"
                className={classes.imageWidth}
              />
              <Box className={classes.booksDetail}>
                <Box>
                  <Typography variant="h6">Computer E-Classes</Typography>
                  <Typography variant="body1">
                    Learn Computer Science and master essential skills like C++,
                    HTML, CSS, JavaScript, Computer Networks, and Security. Our
                    comprehensive courses will equip you with the knowledge to
                    excel in the tech industry. Start your journey to becoming a
                    tech expert today!
                  </Typography>
                </Box>
              </Box>
            </Box>
          </ScrollAnimation>
        </Box>
      </Box>

      <Grid
        container
        px={{ xs: 2, md: 5 }}
        py={{ xs: 3, md: 5 }}
        sx={{
          backgroundColor: "#1b3764",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
        className={classes.bannerOrder}
      >
        <Grid
          xs={12}
          sm={8}
          md={8}
          lg={8}
          mb={{ xs: 5, sm: 0 }}
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xs: "36px", sm: "48px" },
            justifyContent: "center",
          }}
          className={classes.contentContainer}
        >
          <ScrollAnimation animateIn="backInLeft">
            <Typography
              variant="h4"
              sx={{
                color: "white",
                fontWeight: "bold",

                fontSize: { xs: "26px", md: "60px" },
              }}
            >
              Join Now!!!
            </Typography>
          </ScrollAnimation>
          <ScrollAnimation animateIn="backInLeft">
            <Typography variant="body1" sx={{ color: "white" }}>
              Unlock Your Potential with Interactive E-Classes
            </Typography>
          </ScrollAnimation>
          <ScrollAnimation animateIn="backInLeft">
            <Button
              onClick={() => smoothScrollTo("contact")}
              variant="outlined"
              className={classes.linkButton}
              size="large"
              sx={{
                color: "white !important",
                border: "1px solid #ffca42",
                width: { xs: "100%", sm: "300px" },
              }}
            >
              Contact Us
            </Button>
          </ScrollAnimation>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          <img src={banner} alt="Banner" style={{ width: "100%" }} />
        </Grid>
      </Grid>

      <Box id="aboutus">
        <Box container className={classes.introSection}>
          <Box className={classes.authorPic}>
            <img src={profilePic} alt="Profile Pic" />
          </Box>
          <Box className={classes.textSection}>
            <Typography variant="h4">Sara</Typography>
            <Typography variant="body1">
              Sara is an Emirati best-selling author, having written numerous
              books for children and young adults. Bitten by a bookworm as a
              young child, she developed an unquenchable thirst for consuming
              every book she could get her hands on. Sara spent her youth
              visiting libraries and checking out dozens of books at a time
              (taking advantage of her brothers’ library and check out even more
              books for the week). After taking an interest in how language can
              shape meaning across culture, society, media, and cognitive
              processes, she went on to get her BA and MSc in Communication.
              Sara draws from her education to write stories that touch on
              pivotal topics, with the hopes of shaping bright minds to build a
              better tomorrow.
            </Typography>
          </Box>
        </Box>
        <Box container className={classes.introSection}>
          <Box className={classes.textSection}>
            <Typography variant="h4">Sara</Typography>
            <Typography variant="body1">
              Sara is an Emirati best-selling author, having written numerous
              books for children and young adults. Bitten by a bookworm as a
              young child, she developed an unquenchable thirst for consuming
              every book she could get her hands on. Sara spent her youth
              visiting libraries and checking out dozens of books at a time
              (taking advantage of her brothers’ library and check out even more
              books for the week). After taking an interest in how language can
              shape meaning across culture, society, media, and cognitive
              processes, she went on to get her BA and MSc in Communication.
              Sara draws from her education to write stories that touch on
              pivotal topics, with the hopes of shaping bright minds to build a
              better tomorrow.
            </Typography>
          </Box>
          <Box className={classes.authorPic}>
            <img src={profilePic} alt="Profile Pic" />{" "}
          </Box>
        </Box>
      </Box>
      <div
        style={{
          padding: "30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "50px",
        }}
        id="contact"
      >
        <ScrollAnimation animateIn="bounceInRight">
          <Typography variant="h4">Contact us</Typography>
        </ScrollAnimation>
        <div className="card">
          <form>
            <div class="form-group">
              <label for="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                class="form-control"
                required
              />
            </div>
            <div class="form-group">
              <label for="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                class="form-control"
                required
              />
            </div>
            <div class="form-group">
              <label for="description">Description:</label>
              <textarea
                id="description"
                name="description"
                class="form-control"
                rows="4"
                required
              ></textarea>
            </div>
            <button type="submit" class="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div>

      <Grid container px={{ xs: 0.5, sm: 2 }} className={classes.footer}>
        <Grid lg={4} md={4}></Grid>
        <Grid xs={12} sm={8} md={6} lg={6} item>
          <Typography variant="body1">
            Copyright © 2024{" "}
            <span style={{ fontWeight: "700" }}>Bytes Solution</span> - All
            Rights Reserved.
          </Typography>
        </Grid>
        <Grid
          xs={12}
          sm={4}
          md={2}
          lg={2}
          sx={{ justifyContent: "end", display: "flex", gap: "8px" }}
        >
          <Link
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              backgroundColor: "white",
              display: "flex",
              width: "max-content",
            }}
          >
            <FacebookIcon style={{ color: "#0077B5" }} />
          </Link>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              backgroundColor: "white",
              display: "flex",
              width: "max-content",
            }}
          >
            <InstagramIcon style={{ color: "#833AB4" }} />
          </Link>
        </Grid>
      </Grid>
    </>
  );
}

export default App;

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}
